
html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
}


*{
    margin: 0px;
    font-family: Arial, Helvetica, sans-serif;
}


h4{
  font-size: 15px;
}


.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cont{
    box-shadow: #645cff;
}
.container{
    height: 80vh;
    width: 70vw;
    border: 3px solid rgb(8, 5, 5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 40px auto;
}

.login-img{
    height: 90%;
    width: 50%;
    background-color: aqua;
}
.form-container{
background-color: aquamarine;
height: 90%;
}
/* .form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} */

h4{
    text-align: center;
    margin-bottom: 1rem;
}
h1{
    text-align: center;
    padding: 0;
    /* margin-bottom: 2rem; */
}

/* Navbarcss */



.cont{
  width: 100%;
    padding: 0.5rem 1.76%;
    background-color: #FFFFFF;
    box-shadow: 0px 0.1em 5px rgb(226, 217, 217);
    position: fixed;
    z-index: 1;
}
.left{
        width: 35%;
        display: flex;
    justify-content: space-between;
    align-items: center;
}
.search-navbar{
              background-color: #f0f2f5;
            outline: none;
            min-width: 250px;
            border-style:none;
            border-radius: 20px;
            padding: 0.7em;
}
.search-result{
  position: fixed;
  top:55px;
  right: 0px;
  background-color: #FFFFFF;
display: flex;
justify-content: space-between;
gap: 1em;
flex-direction: column;
 min-width: 300px;
 max-height: 60vh;

}
.query-result{
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.2em 3em;
  width: 90%;
  margin: auto;
  border-radius: 8px;
}
.query-result:hover{
 background-color: #f0f2f5;
}

/* brand */
.brand{
cursor: pointer;
color:#645cff;
font-size: 2rem;

    font-weight: bolder;
}

/* mobile view  navbar*/
.mobile-nav{
     position:fixed;
          right : 0px;
          bottom: 0px;
          height:100%;
          z-index: 10;
          /* max-width:500xp ;
          min-width: 300px; */
          width: 20%;
          
          
          background-color: aqua;
}






/* 
right part */
.right{
    width: 20%;
}
.pop,.pop-mobile{
visibility: hidden;
/* display: none; */
}
.onhover:hover + .pop, .onhover:hover + .pop-mobile {
    visibility: visible;
    /* display: block; */
}
.pop{
    position: absolute;
    z-index: 20;
    
}
.pop div,.pop-mobile div  {
    background: rgb(61, 53, 53);
    border-radius: 5px;
   display: flex;
   justify-content: center;
   align-items: center;
    color: white;
}
/* span{
    margin: auto;
    font-size: 0.8rem;
} */
.pop-mobile{
     position: absolute;
    z-index: 20;
    right:0px;
    margin: 0px;
    
}
.logout{
    background-color: rgba(106, 4, 4, 0.525);
}

/* Friend page */

.btn-fri{
       border-radius: 50px;
    padding: 0.4em 1em;
    cursor: pointer;
    border-style: none;
    font-weight: bolder;
    background-color: #d4d9e0;
    
}
.fd-box{
    display: flex;
  justify-content: space-evenly; 
  align-items:center ; 
}
.btn-box{
    width: 70%;
    background-color: #645cff;
}

@media screen and (min-width: 700px) {
    .add-div{
        display: flex;
        flex-direction: column;
width: 100%;
        justify-content: space-between;
        
    }
 .add-div button ,.editfriend-btn {
    width: 90%;
    border-radius: 5px ;
    border-style: none;
    padding: 0.4em 0px;
   
    color: white;
margin: auto;
      margin-bottom: 0.4em;
    cursor: pointer;
}

 .friend-card{
      display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 8px 8px 0 0;
    padding: 1em 0;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1), -1px -1px 2px 2px rgba(0, 0, 0, 0.1);}
} 

@media screen and (max-width: 700px) {
        .add-div{
        display: flex;
        flex-direction: row;

        justify-content: space-between;
        
    }
 .add-div button ,.friendReq-mobilebtn{
    width: 48%;
    border-radius: 5px ;
    border-style: none;
    padding: 0.4em 0px;
    color:white;
 
  
    cursor: pointer;
}

.friend-card{
    /* background-color:#ffffff;
    display:flex;
  justify-content: space-evenly; 
  align-items:center ;  */

      display: flex;

    justify-content: left;
    align-items: center;
    gap:4em;
margin-top: 0.4em;
/* 
    width: 200px;
    min-width: 200px; */
    /* background-color: #ffffff; */
    border-radius: 8px;
    /* padding: 1em 0; */
    /* box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1), -1px -1px 2px 2px rgba(0, 0, 0, 0.1); */
}


}



.sidebar{
position: relative;
    top: 4em;

    background: #ffffff;
    /* height: 90vh; */
    padding: 1em;
    display: flex;
    justify-content: space-around;
    width: 100%;

    /* z-index: -1; */
    /* margin-left: em; */
}
.friend-row{
   
   display: flex;
   justify-content: left;
   align-items: center;
    border-radius: 10px;
    margin-bottom: 2em;
    box-sizing: border-box;
    padding:0.5em;
  
     cursor: pointer;
     
}
.friend-row:hover{

   background-color: #eaeef2;
}

/* //dekstop friend */
.friend-grid{
display: grid;
gap:1em;
grid-template-columns: repeat(auto-fill,minmax(200px,1fr));
margin: auto;
padding: 1.5em;

}

/* profile page */


@media screen and (min-width: 700px){
  .profile-img{

    display: flex;
    justify-content: center;
    padding: 3em;
    
background-color: #ffffff;

}

.profile-friend-grid{
    display: grid;
    width: 90%;
    grid-template-columns: repeat(auto-fill,minmax(150px,1fr));
    gap: 0.59em;
    margin: auto;
    padding: 1.2em;
  
}
}

@media screen and (max-width: 699px){
  .profile-img{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3em;
    
background-color: #ffffff;

}
.profile-info p{
text-align: center;
}
.mobile-about-div{
  display: flex;
  justify-content: center;
  align-items: center;

    padding: 1em 0;
  background-color: #ffffff;
}

.profile-friend-grid{
    display: grid;
    width: 100%;
    
    gap: 0.6em;
 grid-template-columns: repeat(auto-fill,minmax(3,1fr));
    padding: 1em;
    overflow: scroll;
  
}

.profile-info-box{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

      background-color:#ffffff;
  
      border-radius: 8px 8px 0 0;
      padding: 1em 0;
      box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1),-1px -1px 2px 2px rgba(0, 0, 0, 0.1);

}



}

.profile-info{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    margin-left: 2em;

}
.profile-btn{
    border-radius: 5px;
    border-style: none;
    padding: 0.4em 0.5em;
    cursor: pointer;
    margin-top: 1em;
    background-color: #645cff;
    color: white;
}
.info-bar{
    background-color:#ffffff;
}
.info-bar span{
padding: 1.3em;
margin: 0.5em;

 border-radius: 10px;

}
 .info-bar span:hover{
    cursor: pointer; 
   background-color: #f3f3f4;
   box-shadow: 0px 1px 1px blue;
}

.profile-info-box{
    /* display: relative;
    top: 10em; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

      background-color:#ffffff;
      width: 150px;
   
      min-width: 150px;
      border-radius: 8px 8px 0 0;
      padding: 1em 0;
      box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1),-1px -1px 2px 2px rgba(0, 0, 0, 0.1);

}


.about-table {
  font-family: Arial, Helvetica, sans-serif;
  /* border-collapse: collapse; */
  width: 100%;
}

.about-table td,.about-table th {
  border: 1px solid #ddd;
word-wrap: break-word;
/* white-space: pre-wrap; */
word-break: break-word;
width: 50%;

  padding: 1em;
}



.form {
  width: 100%;
  /* max-width: var(--fixed-width); */
  background: #ffffff;
  border-radius:1em;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  padding: 2rem 2.5rem;

  /* margin: 3rem auto; */
}

.form-label {
  display: block;
  font-size:  0.70rem;
  margin-bottom: 0.5rem;
 font-weight: 550;
  letter-spacing: 1px;
}
.form-input,
.form-textarea {
  width: 100%;
  padding: 0.375rem 0.75rem;
  border-radius:0.25rem;
  background:#f0f2f5;
  border: 1px solid #e2e8f0;
}
.form-textarea{
  resize: vertical;
}
.form-row {
  margin-bottom: 1rem;
}

.form-textarea {
  height: 7rem;
}
::placeholder {
  font-family: inherit;

}
input{
  /* background-color: #f0f2f5; */
  background-color: #ced2d8;
  overflow-x: visible;
}

/* div[contenteditable="true"]::before {
    content: attr(placeholder); F
    color: #aaa;
} */

[contenteditable=true]:empty:before{
  content: attr(placeholder);
  pointer-events: none;

  display: block; /* For Firefox */
}

[contenteditable=true]{
  outline: none;
  width: 60vw;
  background-color: #f0f2f5;
  border-radius: 20px;
  padding: 0.5em;
  font-weight: lighter;
  color: rgb(0 0 0 / 55%);

}
[contenteditable=true]::placeholder{
  font-weight: 100;
}



input:focus{
  outline: none;

}
.form-btn {
  cursor: pointer;
  color: #fff;;
  background: #645cff;
  border: transparent;
  border-radius:0.25rem;
  letter-spacing:1px;
  padding: 0.375rem 0.75rem;
  box-shadow:0 1px 3px 0 rgba(0, 0, 0, 0.1);
  transition:0.3s ease-in-out all;
  text-transform: capitalize;
  display: block;
  margin: auto;
  /* width: 50%;
  min-width: 50%; */
}
.form-btn:hover {
  background:#3c3799;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}


/* mobile menu */
.menu-box{
    height: 40vh;
    width: 40vw;
    min-width: 200px;
border-radius: 20px;
  background-color: #ffffff;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.menu-items{
cursor: pointer;
}
.menu-items:hover{
  background-color: #e1e4e6;
}


/* Widget Css */

.flexbox{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu{
    
    width: 200px;
    margin: auto;
    padding: 0.5em;
    cursor: pointer;

}
.menu:hover{
    background-color:#ecedee;
    border-radius: 10px 0px 0px 10px ;
}
.flexleft{
      display: flex;
    justify-content: left;
    align-items: center;  
    margin-top: 1.2em
}
#comment{
    border-radius: 20rem;
    width: 70%;
    height: 2.5rem;
    margin: auto;
    /* height: 70%; */
}
.comment-section form{
    display: flex;
    justify-content: space-between;
    margin-top: 0.5em;
 
}
.comment-section form button,.comment-modal-content form button{
    border-radius: 50%;
    cursor: pointer;
    border: 0px ;

}
.comment{
      display: flex;
    justify-content: left;
    align-items: center;  
    /* margin-bottom: 1em; */
    margin-bottom: 0.8em
}
.comment-modal{

  z-index: 4;
  top:0;
  left:0;
  right:0;
  display: flex;
  justify-content: center;
  align-items: center;
      width: 100vw;
      height: 100vh;

    /* background-color: rgb(223, 200, 200); */
   background-color: rgb(209 212 216 / 45%);
    border-radius: 4px;
margin: 0px auto;

    position: fixed;
}
@media screen and (min-width: 700px) {
.comment-modal-content{
  background-color: #fefefe;
  height: 80%;
  width: 650px;
  position: relative;
  overflow-y: scroll !important

}
        
    }
@media screen and (max-width: 699px) {
.comment-modal-content{
  background-color: #fefefe;

    height: 100%;
  width: 100%;
  position: relative;
  overflow-y: scroll !important

}
        
    }

    

/* .comment-modal-content form button{

} */
.content{
   /* background-color:#dadde1; */
    background-color: #f0f2f5;
     border-radius: 1.1rem;
    padding: 0.4rem;
}


/* alert */
.alert {
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius:  0.25rem;
  text-align: center;
}

.alert-danger {
  color: #842029;
  background: #f8d7da
}
.alert-success {
  color: #0f5132;
  background: #d1e7dd;
}

/* spinner */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  width: 4em;
  height: 4em;
  border: 5px solid rgba(0, 0, 0, 0.1);;
  border-radius: 50%;
  border-top-color:#141415;
  animation: spinner 0.6s linear infinite;
  margin: 0 auto;
}
.loading-center {
  margin: 0 auto;
}


.modal-box{
  /* height: 90vh; */
  display: flex;
  justify-content: center;
  align-items: center;
      width: 30vw;
color: white;
    background-color: rgb(68 64 64);
    border-radius: 4px;


    position: fixed;
    z-index: 10;

  top:10px;
  left: 0;
  right: 0;
  margin: 0 auto;
}


